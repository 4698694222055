import React, { Component } from 'react';
import classes from './TextArea.module.scss';

import { Optional } from '../../index';
import { IFormControlProps, formControl } from '../FormControl';

export interface ITextAreaProps extends IFormControlProps {
  className: string;
}

class Input extends Component<ITextAreaProps> {

  constructor(props: ITextAreaProps) {
    super(props);
  }

  render() {

    return (
      <textarea
        value={this.props.value.toString()}
        onChange={e => this.props.onChange(e.currentTarget.value)}
        className={this.props.className}
        rows={3}
      />
    );
  }

}

export default formControl(Input);