import React, { Component, Fragment } from 'react';

import { IGuest } from '../../App';

import Input from '../../FormControls/Input/Input';
import { propVal } from '../../index';

import classes from './Name.module.scss';

export interface INameProps {
  guest: IGuest;
  handleInputChange: (key: string, value: string, index: number) => void;
  index: number;
}

export default class Name extends Component<INameProps, {}> {
  componentDidMount() {}

  render() {
    return this.props.guest.isPlusOne ? (
      <Fragment>
        <Input
          name="firstName"
          label="First Name"
          size="Medium"
          value={propVal(this.props.guest, ['firstName']).toString()}
          onChange={(value: string) =>
            this.props.handleInputChange('firstName', value, this.props.index)
          }
          bgStyle="Alt"
        />
        <Input
          name="lastName"
          label="Last Name"
          size="Medium"
          value={propVal(this.props.guest, ['lastName']).toString()}
          onChange={(value: string) =>
            this.props.handleInputChange('lastName', value, this.props.index)
          }
          bgStyle="Alt"
        />
      </Fragment>
    ) : (
      <div className={classes.Header}>
        {this.props.guest.firstName} {this.props.guest.lastName}
      </div>
    );
  }
}
