import * as firebase from 'firebase';
import 'firebase/firestore';

// Initialize Firebase
const config = {
  apiKey: 'AIzaSyCljUB9uCCR3StzjA0n5y2BZ3_Po_yvrgc',
  authDomain: 'wedsite-7480c.firebaseapp.com',
  // databaseURL: "https://wedsite-7480c.firebaseio.com",
  projectId: 'wedsite-7480c',
  // storageBucket: "wedsite-7480c.appspot.com",
  // messagingSenderId: "589304642468"
};
firebase.initializeApp(config);

export const db = firebase.firestore();

// Disable deprecated features
db.settings({
  timestampsInSnapshots: true,
});

// db.collection('coldstart-workaround').get()
//   .then(p => console.log('Coldstart worked'))
//   .catch(p => console.log('Cold start did not work'));
