import { IGuest } from './App';

export const guestNames = (guests: IGuest[]): string => {
  const names = guests
    .map(guest => guest.firstName)
    .filter(name => name !== '');

  if (names.length === 1) {
    return names[0];
  } else {
    const last = names.slice(-1);
    const rest = names.slice(0, names.length - 1);
    const nameList = rest.join(', ');

    return `${nameList} and ${last}`;
  }
};
