import React, { Component } from 'react';
import classes from './Input.module.scss';

import { Optional } from '../../index';
import { IFormControlProps, formControl } from '../FormControl';

export interface IInputProps extends IFormControlProps {
  placeholder?: string;
  className: string;
}

class Input extends Component<IInputProps> {
  constructor(props: IInputProps) {
    super(props);
  }

  render() {
    return (
      <input
        type="text"
        spellCheck={false}
        value={this.props.value.toString()}
        placeholder={this.props.placeholder}
        onChange={e => this.props.onChange(e.currentTarget.value)}
        className={this.props.className}
        name={this.props.name}
      />
    );
  }
}

export default formControl(Input);
