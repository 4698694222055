import React, { Component } from 'react';
import classes from './TravelInfo.module.scss';
import Button from '../../Button/Button';

export interface ITravelInfoProps {
  heading: string;
  content: string;
  postcode: string;
  googleMapsLinks: {
    link: string;
    label: string;
  }[];
}

export default class TravelInfo extends Component<ITravelInfoProps, {}> {

  get mapLinkHeader(): string {

    const { googleMapsLinks } = this.props;

    if (googleMapsLinks.length === 0) {
      return '';
    } else {
      let text = 'Google Map Link';
      
      if (googleMapsLinks.length > 1) {
        text += 's';
      }

      return text; 
    }
  }

  render() {
    return (
      <div className={classes.Root}>
        <div className={classes.Heading}>{this.props.heading}</div>
        <div className={classes.Postcode}>Postcode: {this.props.postcode}</div>
        <div className={classes.GoogleMapsLinks}>
          <div>{this.mapLinkHeader}</div>
          <div className={classes.Links}>
            {this.props.googleMapsLinks.map(entry => (
              <Button key={entry.label} type="Button" style="Plain">
                <a href={entry.link} target="_blank">
                  {entry.label}
                </a>
              </Button>
            ))}
          </div>
        </div>
        <div className={classes.Content}>{this.props.content}</div>
      </div>
    );
  }
}
