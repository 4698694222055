import React from 'react';
import ReactDOM from 'react-dom';
import './index.module.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

export type Optional<T> = T | undefined;

export function propVal(object: any, property: string[]): any {
  const [nextProp, ...rest] = property;

  if (property.length === 0 || object === undefined || object === null) {
    return object === undefined || object === null ? '' : object;
  } else {
    return propVal(object[nextProp], rest);
  }
}

// import './HiLoDeco.ttf';
// import './Roselyne TTF.ttf';

// import './firebase';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
