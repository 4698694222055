import React, { Component, FormEvent } from 'react';

import classes from './Form.module.scss';

export interface IFormProps {
  onSubmit: (e: FormEvent) => void;
  alignment?: 'Right' | 'Left' | 'Center'; 
  bgStyle?: 'Main' | 'Alt'; 
}

export default class Form extends Component<IFormProps, {}> {

  render() {

    const classesToApply = [classes.Root];

    switch (this.props.alignment) {
      case 'Right': classesToApply.push(classes.AlignRight); break;
      case 'Left': classesToApply.push(classes.AlignLeft); break;
      default: classesToApply.push(classes.AlignCenter); break;
    }

    switch (this.props.bgStyle) {
      case 'Main': classesToApply.push(classes.BgMain); break;
      case 'Alt': classesToApply.push(classes.BgAlt); break;
    }

    return (
      <form onSubmit={(e) => this.props.onSubmit(e)} className={classesToApply.join(' ')}>
        { this.props.children }
      </form>
    );
  }

}

