import React, { Component, Fragment, ReactFragment } from 'react';
import { IGuest } from '../App';
import classes from './Information.module.scss';
import Bouquet from '../Bouquet/Bouquet';

import bouquet from '../images/bouquet1.png';
import Emphasis from '../Emphasis/Emphasis';
import Block from '../Block/Block';

interface IInformationProps {
  guests: IGuest[];
}

export default class When extends Component<IInformationProps, {}> {
  get ceremonyInfo(): ReactFragment {
    return (
      <Fragment>
        The wedding ceremony will take place in the Great Hall, Smithills Hall
        at <Emphasis>2:30pm</Emphasis>. Please arrive by{' '}
        <Emphasis>2pm</Emphasis> to take your seats.
      </Fragment>
    );
    ``;
  }

  get mealInfo(): ReactFragment {
    return (
      <Fragment>
        We will head over to The Barlow Institute around{' '}
        <Emphasis>4pm</Emphasis> for dinner followed by live music and dancing!
      </Fragment>
    );
  }

  get eveningInfo(): ReactFragment {
    return (
      <Fragment>
        The evening celebrations, with live music and dancing, will take place
        at The Barlow Institute, Edgworth at <Emphasis>8pm</Emphasis>.
      </Fragment>
    );
  }

  get isCeremonyInvitation(): boolean {
    for (let guest of this.props.guests) {
      if (guest.isCeremonyGuest) {
        return true;
      }
    }

    return false;
  }

  render() {
    return (
      <div className={classes.Root}>
        <div className={classes.Container}>
          <div className={classes.BouquetLargeScreen}>
            <Bouquet image={bouquet} side="Left" />
          </div>
          <Bouquet side="Right" image={bouquet} />
          <h1 className={classes.Heading}>
            The big day is <Emphasis>1st June 2019</Emphasis>
          </h1>
          {this.isCeremonyInvitation ? (
            [this.ceremonyInfo, this.mealInfo].map(info => (
              <div className={classes.Info}>{info}</div>
            ))
          ) : (
            <div className={classes.Info}>{this.eveningInfo}</div>
          )}

          <Block heading="Children">
            We would love to share our big day with as many people as possible.
            However, due to capacity restrictions we are unable to accommodate
            children outside of our immediate family. Babes in arms are also
            very welcome.
          </Block>

          <Block heading="Gifts">
            Sharing our day with our friends and family is the best gift we
            could ask for. If you would like to send us a gift, we would greatly
            appreciate a donation to our honeymoon, as we have already
            established a home together. This can be left in a card or
            transferred to our honeymoon fund at:
            <div className={classes.CenterLink}>
              <a href="https://paypal.me/fmonaghan" target="_blank">
                paypal.me/fmonaghan
              </a>
            </div>
          </Block>

          <Block heading="Accommodation">
            The nearest hotel is at The Last Drop Village, approximately halfway
            between The Barlow Institute and Smithills Hall. The Barlow
            Institute is within 20 minutes car journey of either Bolton or
            Blackburn town centres where more hotels may be found. There are
            also a number of local B&Bs
          </Block>
        </div>
      </div>
    );
  }
}
