import React, { Component } from 'react';

import { Optional } from '../../../index';

export interface IOptionProps {
  value: Optional<string | string[] | number>;
}

export default class Option extends Component<IOptionProps, {}> {
  render() {
    return <option value={this.props.value}>{this.props.children}</option>;
  }
}
