import React, { Component, ComponentType } from 'react';

import { Optional } from '../index';

import classes from './FormControl.module.scss';
import { any } from 'prop-types';

export interface IFormControlProps {
  onChange: (value: string) => void;
  label?: Optional<string>;
  name: string;
  size?: Optional<'Large' | 'Medium' | 'Small'>;
  value: string | string[] | number | boolean;
  align?: Optional<'Center' | 'Right' | 'Left'>;
  bgStyle?: Optional<'Main' | 'Alt'>;
}

export const formControl = (
  WrappedComponent: ComponentType<any & IFormControlProps>
) => {
  return class extends Component<any & IFormControlProps, {}> {
    classBuilder = () => {
      const classesToApply = [classes.Input];

      if (this.props.size) {
        classesToApply.push(classes[this.props.size]);
      }

      switch (this.props.align) {
        case 'Center':
          classesToApply.push(classes.AlignCenter);
          break;
        case 'Left':
          classesToApply.push(classes.AlignLeft);
          break;
        default:
          classesToApply.push(classes.AlignRight);
          break;
      }

      switch (this.props.bgStyle) {
        case 'Alt':
          classesToApply.push(classes.BgAlt);
          break;
        default:
          classesToApply.push(classes.BgMain);
          break;
      }

      return classesToApply.join(' ');
    };

    label() {
      if (!this.props.label) {
        return null;
      }

      const classesToApply = [classes.Label];

      switch (this.props.bgStyle) {
        case 'Alt':
          classesToApply.push(classes.BgAlt);
          break;
        default:
          classesToApply.push(classes.BgMain);
          break;
      }

      return (
        <label htmlFor={this.props.name} className={classesToApply.join(' ')}>
          {this.props.label}
        </label>
      );
    }

    render() {
      return (
        <div className={classes.Root}>
          {this.label()}
          <WrappedComponent {...this.props} className={this.classBuilder()} />
        </div>
      );
    }
  };
};
