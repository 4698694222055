import React, { Component, FormEvent } from 'react';
import { Redirect } from 'react-router';

import bouquet from '../images/bouquet1.png';
import classes from './Splash.module.scss';

import Form from '../Form/Form';
import Input from '../FormControls/Input/Input';
import Button from '../Button/Button';
import Bouquet from '../Bouquet/Bouquet';
import SubmitMessage from '../SubmitMessage/SubmitMessage';

export interface ISplashProps {
  isInvitationIdMatched: boolean;
  onRetrieveInvitation: (e: FormEvent, invitationId: string) => void;
  submitMessage?: string | string[];
}

export interface ISplashState {
  invitationId?: string;
}

export default class Splash extends Component<ISplashProps, ISplashState> {
  constructor(props: ISplashProps) {
    super(props);
    this.state = {
      invitationId: '',
    };
  }

  handleRetrieveInvitation = (e: FormEvent) => {
    e.preventDefault();
    this.props.onRetrieveInvitation(e, this.state.invitationId || '');
  };

  handleTextInputChange = (key: string, value: string) => {
    this.setState({
      [key]: value,
    });
  };

  get renderSubmitMessage(): JSX.Element {
    return this.props.submitMessage ? (
      <SubmitMessage message={this.props.submitMessage} />
    ) : null;
  }

  render() {
    if (this.props.isInvitationIdMatched) {
      return <Redirect to="/welcome" />;
    }

    return (
      <div className={classes.Root}>
        <Bouquet image={bouquet} side="Left" />
        <Bouquet image={bouquet} side="Right" />
        <div className={classes.Content}>
          <div className={classes.Welcome}>
            <div className={classes.Names}>
              <span className={classes.Cursive}>Joanne Currell</span> and{' '}
              <span className={classes.Cursive}>Frank Monaghan</span>
            </div>
            <div>Wedding 1st June 2019</div>
          </div>

          <div className={classes.Form}>
            <Form onSubmit={this.handleRetrieveInvitation}>
              <Input
                name="invitationId"
                size="Large"
                align="Center"
                value={this.state.invitationId || ''}
                onChange={(value: string) =>
                  this.handleTextInputChange('invitationId', value)
                }
                placeholder="Enter invitation code"
              />
              {this.renderSubmitMessage}
              <Button type="submit" style="Plain">
                Enter
              </Button>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}
