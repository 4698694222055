import React, { Component } from 'react';

import Option from './Option/Option';
import { Optional } from '../../index';
import { IFormControlProps, formControl } from '../FormControl';

import classes from './Select.module.scss';

interface ISelectProps extends IFormControlProps {
  options: {
    value: Optional<string | string[] | number>;
    text: string;
  }[];
  className: string;
}

class Select extends Component<ISelectProps, {}> {
  renderOptions() {
    return this.props.options.map(opt => (
      <Option key={opt.value as string} value={opt.value}>
        {opt.text}
      </Option>
    ));
  }

  render() {
    const className = [this.props.className, classes.Root].join(' ');

    return (
      <select
        onChange={e => this.props.onChange(e.currentTarget.value)}
        className={className}
        value={this.props.value.toString()}
      >
        {this.renderOptions()}
      </select>
    );
  }
}

export default formControl(Select);
