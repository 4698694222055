import React, { Component } from 'react';

import classes from './Box.module.scss';

export default class Box extends Component<{}, {}> {
  render() {
    return (
      <div className={classes.Root}>
        <div className={classes.Content}>
          <div className={classes.Container}>
            <div className={classes.Box}>{this.props.children}</div>
          </div>
        </div>
      </div>
    );
  }
}
