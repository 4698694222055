import React, { Component } from 'react';
import classes from './Block.module.scss';

interface IBlockProps {
  heading: string;
}

export default class Block extends Component<IBlockProps, {}> {
  render() {
    return (
      <p className={classes.Root}>
        <h3>{this.props.heading}</h3>
        <div className={classes.Info}>{this.props.children}</div>
      </p>
    );
  }
}
