import React, { Component, Fragment } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

import { Optional } from '../index';
import Button from '../Button/Button';

import classes from './Navbar.module.scss';

interface INavbarState {
  isOpen: Optional<boolean>;
}

interface INavbarProps {}

class Navbar extends Component<
  INavbarProps & RouteComponentProps,
  INavbarState
> {
  state: INavbarState;

  constructor(props: INavbarProps & RouteComponentProps) {
    super(props);
    this.state = {
      isOpen: false,
    };
  }

  handleToggleMenu = (toggle: boolean): void => {
    if (!toggle) {
      return;
    }

    this.setState(prevState => {
      return {
        isOpen: !prevState.isOpen,
      };
    });
  };

  createLinks(toggle = false): JSX.Element {
    return (
      <Fragment>
        {[
          {
            to: 'welcome',
            text: 'Welcome',
          },
          {
            to: 'info',
            text: 'Information',
          },
          {
            to: 'rsvp',
            text: 'RSVP',
          },
          {
            to: 'travel',
            text: 'Getting There',
          },
        ].map(link => {
          return (
            <div>
              <NavLink
                to={`/${link.to}`}
                className={classes.NavLink}
                activeClassName={classes.Active}
                onClick={() => this.handleToggleMenu(toggle)}
              >
                {link.text}
              </NavLink>
            </div>
          );
        })}
      </Fragment>
    );
  }

  navbarSmallDevice(): JSX.Element {
    const classesToApply = [classes.Root, classes.Small];

    if (!this.state.isOpen) {
      return (
        <div className={classesToApply.join(' ')}>
          <Button
            type="button"
            style="Icon Light"
            onClick={() => this.handleToggleMenu(true)}
          >
            <FontAwesomeIcon icon={faBars} size="3x" />
          </Button>
        </div>
      );
    }

    classesToApply.push(classes.Open);

    return (
      <nav className={classesToApply.join(' ')}>
        <div className={classes.Close}>
          <Button
            type="button"
            style="Icon Dark"
            onClick={() => this.handleToggleMenu(true)}
          >
            <FontAwesomeIcon icon={faTimes} size="3x" />
          </Button>
        </div>
        {this.createLinks(true)}
      </nav>
    );
  }

  navbarLargeDevice(): JSX.Element {
    return (
      <nav className={`${classes.Root} ${classes.Large}`}>
        {this.createLinks()}
      </nav>
    );
  }

  render() {
    if (this.props.location.pathname === '/') {
      return null;
    }
    return (
      <Fragment>
        {this.navbarLargeDevice()}
        {this.navbarSmallDevice()}
      </Fragment>
    );
  }
}

export default withRouter<RouteComponentProps>(Navbar);
