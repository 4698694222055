import React, { Component, FormEvent } from 'react';

import Form from '../Form/Form';
import { IGuest } from '../App';
import { db } from '../firebase';
import Button from '../Button/Button';
import classes from './Reply.module.scss';
import Bouquet from '../Bouquet/Bouquet';

import bouquet from '../images/bouquet9.png';
import Box from '../Box/Box';
import Name from './Name/Name';
import Status from './Status/Status';
import { guestNames } from '../common';
import SubmitMessage from '../SubmitMessage/SubmitMessage';

interface IReplyProps {
  guests: IGuest[];
  onSubmit?: (guests: IGuest[]) => void;
}

interface IReplyState {
  guests?: IGuest[];
  submitMessage: string | string[];
  replies: {
    success: IGuest[];
    failure: IGuest[];
  };
}

export default class Reply extends Component<IReplyProps, IReplyState> {
  state: IReplyState;

  constructor(props: IReplyProps) {
    super(props);
    this.state = {
      guests: props.guests,
      submitMessage: null,
      replies: {
        success: [],
        failure: [],
      },
    };
  }

  handleSubmitReply = async (e: FormEvent) => {
    e.preventDefault();
    // console.log(this.state.guests);
    // const replies = {
    //   success: [],
    //   failure: [],
    // };
    this.setState({
      replies: {
        failure: [],
        success: [],
      },
    });

    const guestsRef = db.collection('guests');

    await this.state.guests!.forEach(guest => {
      guestsRef
        .doc(guest.id)
        .update({ ...guest })
        .then(() => {
          this.setState(prevState => {
            return {
              replies: {
                success: [...prevState.replies.success, guest],
                failure: [...prevState.replies.failure],
              },
            };
          });
        })
        .catch(() => {
          this.setState(prevState => {
            return {
              replies: {
                success: [...prevState.replies.success],
                failure: [...prevState.replies.failure, guest],
              },
            };
          });
        });
    });

    this.props.onSubmit(this.state.guests);
  };

  get submitMessage(): string[] {
    const messageOutput =
      this.state.replies.success.length > 0 ||
      this.state.replies.failure.length > 0
        ? ['Thanks for your reply!']
        : [];

    const isGoing = this.state.replies.success.filter(reply => reply.isGoing);
    const isNotGoing = this.state.replies.success.filter(
      reply => !reply.isGoing
    );

    console.log(isGoing, isNotGoing);

    if (isGoing.length > 0) {
      messageOutput.push(
        `We're looking forward to seeing you there ${guestNames(isGoing)}!`
      );
    }

    if (isNotGoing.length > 0) {
      messageOutput.push(`Sorry you can't make it ${guestNames(isNotGoing)}`);
    }

    if (this.state.replies.failure.length > 0) {
      messageOutput.push(
        `Unfortunately, there was a problem updating the replies for ${guestNames(
          this.state.replies.failure
        )}. Please try again.`
      );
    }

    return messageOutput;
  }

  handleInputChange = (
    key: string,
    value: string,
    guestIndex: number,
    type = 'string'
  ) => {
    let actualValue: string | number | boolean;

    const guests: IGuest[] = [...this.state.guests!];
    const guest: IGuest = { ...guests[guestIndex] };

    switch (type) {
      case 'boolean':
        actualValue = true.toString() === value;
        break;
      case 'number':
        actualValue = Number(value);
        break;
      default:
        actualValue = value;
    }

    // console.log(key, value, type, actualValue, guests, guest);

    guest[key] = actualValue;
    guests[guestIndex] = guest;

    this.setState({
      guests: guests,
    });
  };

  render() {
    // console.log(propVal(this.state.guests[0], ['isGoing']).toString())

    return (
      <div className={classes.Root}>
        <Bouquet image={bouquet} side="Right" />
        <h2 className={classes.PageTitle}>RSVP</h2>
        <Form onSubmit={this.handleSubmitReply} bgStyle="Alt">
          {/* <div> */}
          {this.state.guests!.map((guest, i) => {
            return (
              <div key={guest.id} className={classes.GuestForm}>
                <div className={classes.Invitation}>
                  <div className={classes.CircleGoing}>
                    <Box>
                      <Name
                        guest={guest}
                        handleInputChange={this.handleInputChange}
                        index={i}
                      />
                    </Box>
                  </div>
                  <div className={classes.CircleOtherInfo}>
                    <Box>
                      <Status
                        guest={guest}
                        handleInputChange={this.handleInputChange}
                        index={i}
                      />
                    </Box>
                  </div>
                </div>
              </div>
            );
          })}
          <div className={classes.SubmitMessage}>
            <SubmitMessage message={this.submitMessage} />
          </div>
          <Button type="submit" style="Plain">
            Reply Now
          </Button>

          {/* <select */}
          {/* </div> */}
        </Form>
      </div>
    );
  }
}
