import React from 'react';

import { Redirect, Route, RouteProps } from 'react-router-dom';

export interface IPrivateRouteProps extends RouteProps {
  isAuthenticated: boolean;
  renderedComponent: JSX.Element;
}

// ts-lint: ignore
export default function PrivateRoute({
  isAuthenticated: auth,
  ...rest
}: IPrivateRouteProps) {
  return (
    <Route
      {...rest}
      render={props => {
        return auth ? (
          rest.renderedComponent
        ) : (
          <Redirect to={{ pathname: '/', state: { from: props.location } }} />
        );
      }}
    />
  );
}
