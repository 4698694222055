import React, { Component } from 'react';

import classes from './Bouquet.module.scss';

interface IBouquetProps {
  image: string;
  side: 'Right' | 'Left';
}

export default class Bouquet extends Component<IBouquetProps, {}> {
  render() {
    return (
      <img
        src={this.props.image}
        className={[classes.Bouquet, classes[this.props.side]].join(' ')}
      />
    );
  }
}
