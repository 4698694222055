import React, { Component, MouseEvent } from 'react';
import classes from './Button.module.scss';

interface IButtonProps {
  type: string;
  style: 'Icon Dark' | 'Icon Light' | 'Icon' | 'Plain' | 'Dark';
  onClick?: (e: MouseEvent) => void;
}

export default class Button extends Component<IButtonProps, {}> {
  render() {
    const classesToApply = [classes.Root];

    const styles = this.props.style.split(' ');
    for (let style of styles) {
      classesToApply.push(classes[style]);
    }

    return (
      <button
        type={this.props.type}
        className={classesToApply.join(' ')}
        onClick={this.props.onClick}
      >
        {this.props.children}
      </button>
    );
  }
}
