import React, { Component, Fragment } from 'react';

import { IGuest } from '../../App';

import Select from '../../FormControls/Select/Select';
import TextArea from '../../FormControls/TextArea/TextArea';
import { propVal } from '../../index';

import classes from './Status.module.scss';

export interface IStatusProps {
  guest: IGuest;
  handleInputChange: (
    key: string,
    value: string,
    index: number,
    type?: string
  ) => void;
  index: number;
}

export default class Status extends Component<IStatusProps, {}> {
  componentDidMount() {}

  render() {
    return (
      <Fragment>
        <Select
          name="isGoing"
          label="Going"
          size="Medium"
          value={propVal(this.props.guest, ['isGoing']).toString()}
          onChange={(value: string) =>
            this.props.handleInputChange(
              'isGoing',
              value,
              this.props.index,
              'boolean'
            )
          }
          options={[
            {
              value: 'true',
              text: `Count me in!`,
            },
            {
              value: 'false',
              text: `Sorry, I can't make it`,
            },
          ]}
          bgStyle="Alt"
        />
        <TextArea
          name="dietaryRequirements"
          label="Dietary Requirements / Other Notes"
          size="Medium"
          value={propVal(this.props.guest, ['dietaryRequirements']).toString()}
          onChange={(value: string) =>
            this.props.handleInputChange(
              'dietaryRequirements',
              value,
              this.props.index
            )
          }
          bgStyle="Alt"
        />
      </Fragment>
    );
  }
}
