import React, { Component } from 'react';
import { IGuest } from '../App';
import classes from './Welcome.module.scss';
import Bouquet from '../Bouquet/Bouquet';

import bouquet from '../images/bouquet1.png';
import { Link } from 'react-router-dom';
import { guestNames } from '../common';

interface IWelcomeProps {
  guests: IGuest[];
}

export default class Welcome extends Component<IWelcomeProps, {}> {
  get ceremonyInvite(): string {
    return ` the ceremony and the following celebrations`;
  }

  get eveningInvite(): string {
    return ' the evening celebrations';
  }

  get isCeremonyInvitation(): boolean {
    for (let guest of this.props.guests) {
      if (guest.isCeremonyGuest) {
        return true;
      }
    }

    return false;
  }

  render() {
    return (
      <div className={classes.Root}>
        <div className={classes.Container}>
          <Bouquet side="Right" image={bouquet} />
          <div className={classes.Content}>
            <h1 className={classes.WelcomeMessage}>
              Hi, {guestNames(this.props.guests)}!
            </h1>
            <div>
              <div
                className={[classes.Image, classes.ImageContent].join(' ')}
              />
              <div className={classes.Paragraph}>
                Along with our families, we are delighted to invite you to join
                us in celebrating our wedding day!
              </div>
              <div className={classes.Paragraph}>
                The ceremony will take place in the Great Hall inside Smithills
                Hall, Bolton, before moving on to a reception, with dinner and
                dancing, at the Barlow Institute in Edgworth.
              </div>
              <div className={classes.Paragraph}>
                We'd love for you to join us for
                {this.isCeremonyInvitation
                  ? this.ceremonyInvite
                  : this.eveningInvite}{' '}
                (make sure you bring your dancing shoes!!!).
              </div>
              <div className={classes.Paragraph}>
                You can reply to the invitation on the{' '}
                <Link to="/rsvp" className={classes.Link}>
                  RSVP
                </Link>{' '}
                page, and also find lots of other helpful information on this
                site.
              </div>
              <div className={classes.Paragraph}>
                Thanks, and we look forward to seeing you on 1st June 2019!
              </div>
              <div className={classes.Paragraph}>Jo and Frank xxx</div>
            </div>
          </div>
          <div className={[classes.Image, classes.ImageFlex].join(' ')} />
        </div>
      </div>
    );
  }
}
