import React, { Component } from 'react';
import classes from './Travel.module.scss';

import Information from '../Information/Information';
import TravelInfo from './TravelInfo/TravelInfo';
import Block from '../Block/Block';

export interface ITravelProps {
  isCeremonyInvitation: boolean;
}

export default class Travel extends Component<ITravelProps, {}> {
  get directionsFromAsdaToBarlow(): string {
    return 'After another 0.75 miles, turn left at the next major junction on to Tonge Moor Road. Just after passing Canon Slade School, take a left up the hill onto Turton Road. After approx. 0.5 miles, immediately after passing under the railway bridge, take a right on to Chapeltown Road, towards Chapeltown. Follow this road for approx 1.5 miles, past Jumbles Country Park. Just before reaching Chapeltown, follow the road right onto Bolton Road, towards Edgworth. Follow Bolton Road for about 0.75 miles and The Barlow Institute is on the right hand side.';
  }

  get ceremonyVersion(): JSX.Element[] {
    return [
      <TravelInfo
        postcode="BL1 7NP"
        heading="Smithills Hall from the South and East, via M60 (both directions)"
        googleMapsLinks={[
          {
            link:
              'https://www.google.com/maps/dir/53.5251639,-2.3424975/Smithills+Hall,+Smithills+Dean+Road,+Bolton/@53.5631022,-2.4869141,12z/am=t/data=!3m1!4b1!4m9!4m8!1m0!1m5!1m1!1s0x487ba7159b075a2f:0x260ca8ad8dfebeb3!2m2!1d-2.456088!2d53.602532!3e0',
            label: 'M60 Anti-clockwise',
          },
          {
            link:
              'https://www.google.com/maps/dir/53.5072419,-2.3755648/Smithills+Hall,+Smithills+Dean+Road,+Bolton/@53.5548893,-2.4968132,12z/am=t/data=!3m1!4b1!4m9!4m8!1m0!1m5!1m1!1s0x487ba7159b075a2f:0x260ca8ad8dfebeb3!2m2!1d-2.456088!2d53.602532!3e0',
            label: 'From M60 Clockwise',
          },
        ]}
        content="Take junction 15 from M60 onto M61 towards Bolton/Wigan/Preston then follow signs for A666. Follow the A666 until it merges right on to Blackburn Road next to a Mercedes Garage. Take an immediate left on to A6099, Halliwell Road and follow for approximately 1 mile to a large junction with traffic lights next to the Ainsworth Arms. Continue straight onto Smithills Dean Road. Smithills Hall is about half a mile up the road, on the right hand side."
        key="Smithills Hall via M60"
      />,
      <TravelInfo
        postcode="BL1 7NP"
        heading="Smithills Hall from the North, via M61"
        googleMapsLinks={[
          {
            link:
              'https://www.google.com/maps/dir/53.5564771,-2.4899422/53.6023861,-2.4577772/@53.5795249,-2.5062123,13z/am=t/data=!3m1!4b1!4m9!4m8!1m5!3m4!1m2!1d-2.4810006!2d53.5652668!3s0x487ba789bc252df3:0x593869e4ecc3af5d!1m0!3e0',
            label: 'From M61',
          },
        ]}
        content="Take junction 5 from M61 onto A58 towards Bolton. Follow for half a mile then turn left on to Beaumont Road at the Shell garage. At the end of Beaumont Road (A58), go straight on up the hill at the lights (a dog leg right then left), staying on the A58, Victoria Road. Follow this road for approximately 2 miles, through traffic lights and over a roundabout. After about 1.5 miles, Moss Bank Park will be on the left. At the next major set of lights after the park, turn left onto Smithills Dean Road and Smithills Hall is about half a mile away, on the right hand side."
        key="Smithills Hall from the North, via M61"
      />,
      <TravelInfo
        postcode="BL7 0AP"
        heading="The Barlow from Smithills Hall"
        googleMapsLinks={[
          {
            link:
              'https://www.google.com/maps/dir/53.6018215,-2.4584841/The+Barlow+Edgworth,+Bolton+Road,+Bolton/@53.6209439,-2.4611648,13z/am=t/data=!3m1!4b1!4m19!4m18!1m10!3m4!1m2!1d-2.4445945!2d53.5990117!3s0x487ba71990a9a069:0x6e96759bb2aa5703!3m4!1m2!1d-2.434178!2d53.6009215!3s0x487ba71e2d81195d:0x7640086587b1305e!1m5!1m1!1s0x487ba15c99478101:0x9ae0e0aa6c2b5946!2m2!1d-2.3932205!2d53.6452243!3e0',
            label: 'Smithills Hall to The Barlow',
          },
        ]}
        content={`Turn left out of the Smithills Hall grounds, down the hill towards the A58. Take a left at the traffic lights and follow the A58, continuing on through the traffic lights by Asda. ${
          this.directionsFromAsdaToBarlow
        }`}
        key="The Barlow from Smithills Hall"
      />,
    ];
  }

  get eveningVersion(): JSX.Element[] {
    return [
      <TravelInfo
        postcode="BL7 0AP"
        heading="The Barlow from the South and East, via M60 (both directions)"
        googleMapsLinks={[
          {
            link:
              'https://www.google.com/maps/dir/53.5251639,-2.3424975/The+Barlow+Edgworth,+Bolton+Road,+Bolton/@53.5844517,-2.4577398,12z/am=t/data=!3m1!4b1!4m19!4m18!1m10!3m4!1m2!1d-2.4308013!2d53.5908992!3s0x487ba6fd0cb584e5:0xf2b5ea90ffc46051!3m4!1m2!1d-2.4328561!2d53.600352!3s0x487ba6e1dbd443b5:0x307c23f28787c52b!1m5!1m1!1s0x487ba15c99478101:0x9ae0e0aa6c2b5946!2m2!1d-2.3932205!2d53.6452243!3e0',
            label: 'From M60 Anti-clockwise',
          },
          {
            link:
              'https://www.google.com/maps/dir/53.5080075,-2.3743696/The+Barlow+Edgworth,+Bolton+Road,+Bolton/@53.5706123,-2.4391625,11.74z/am=t/data=!4m19!4m18!1m10!3m4!1m2!1d-2.4308013!2d53.5908992!3s0x487ba6fd0cb584e5:0xf2b5ea90ffc46051!3m4!1m2!1d-2.4328561!2d53.600352!3s0x487ba6e1dbd443b5:0x307c23f28787c52b!1m5!1m1!1s0x487ba15c99478101:0x9ae0e0aa6c2b5946!2m2!1d-2.3932205!2d53.6452243!3e0',
            label: 'From M60 Clockwise',
          },
        ]}
        content={`Take junction 15 from M60 onto M61 towards Bolton/Wigan/Preston then follow signs for A666. Follow the A666 until it merges right on to Blackburn Road next to a Mercedes Garage. Follow Blackburn Road for 0.75 miles to a large junction with an Asda on the left. Take a right at the traffic lights on to the A58. ${
          this.directionsFromAsdaToBarlow
        }`}
        key="The Barlow via M60"
      />,
      <TravelInfo
        postcode="BL7 0AP"
        heading="The Barlow from the North, via M61"
        googleMapsLinks={[
          {
            link:
              'https://www.google.com/maps/dir/53.5535787,-2.4845919/The+Barlow+Edgworth,+Bolton+Road,+Bolton/@53.5991529,-2.5122756,12z/am=t/data=!3m1!4b1!4m29!4m28!1m20!3m4!1m2!1d-2.4810006!2d53.5652668!3s0x487ba789bc252df3:0x593869e4ecc3af5d!3m4!1m2!1d-2.4828869!2d53.5833634!3s0x487ba77fc666747d:0xfe49656996d8276!3m4!1m2!1d-2.4445945!2d53.5990117!3s0x487ba71990a9a069:0x6e96759bb2aa5703!3m4!1m2!1d-2.434178!2d53.6009215!3s0x487ba71e2d81195d:0x7640086587b1305e!1m5!1m1!1s0x487ba15c99478101:0x9ae0e0aa6c2b5946!2m2!1d-2.3932205!2d53.6452243!3e0',
            label: 'From M61',
          },
        ]}
        content={`Take junction 5 from M61 onto A58 towards Bolton. Follow for half a mile then turn left on to Beaumont Road at the Shell garage. At the end of Beaumont Road (A58), go straight on up the hill at the lights (a dog leg right then left), staying on the A58, Victoria Road. Follow this road for approximately 2 miles, through traffic lights and over a roundabout. After about 1.5 miles, Moss Bank Park will be on the left. Continue straight on at all sets of traffic lights, staying on the A58 past Asda. ${
          this.directionsFromAsdaToBarlow
        }`}
        key="The Barlow from the North, via M61"
      />,
    ];
  }

  get mapClasses(): string {
    const classesToApply = [classes.Map];
    if (this.props.isCeremonyInvitation) {
      classesToApply.push(classes.CeremonyMap);
    } else {
      classesToApply.push(classes.EveningMap);
    }

    return classesToApply.join(' ');
  }

  render() {
    return (
      <div className={classes.Root}>
        {/* <Information> */}
        <div className={classes.ExtraInfoContainer}>
          <div className={classes.ExtraInfo}>
            <Block heading="Parking">
              {this.props.isCeremonyInvitation &&
                'There is ample parking at Smithills Hall, on the left hand side, approx. 150m along the driveway, just before the flats/converted stable buildings. There are also limited spaces directly next to the hall.'}
              The Barlow Institute has limited parking on the grounds, which is
              also shared with the local Cricket club. We encourage guests to
              car share or take a taxi if possible, but there is normally lots
              of on street parking in the vicinity. very welcome.
            </Block>
            <Block heading="Taxi">
              Some local taxi numbers are listed below. We recommend that these
              are booked in advance.
              <table className={classes.TaxiNumberTable}>
                <thead />
                <tbody>
                  <tr>
                    <th>Radio Cars</th>
                    <td>01204 302818</td>
                  </tr>
                  <tr>
                    <th>Orbit/Heaton Private Hire</th>
                    <td>01204 383783 or 491149</td>
                  </tr>
                </tbody>
              </table>
            </Block>
          </div>
        </div>

        <div className={classes.DirectionsContainer}>
          <div className={classes.Directions}>
            {this.props.isCeremonyInvitation
              ? this.ceremonyVersion
              : this.eveningVersion}
          </div>
          <div className={this.mapClasses} />
        </div>
      </div>
    );
  }
}
