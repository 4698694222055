import React, { Component, Fragment } from 'react';

import classes from './SubmitMessage.module.scss';

export interface ISubmitMessageProps {
  message: string | string[];
}

export default class SubmitMessage extends Component<ISubmitMessageProps, {}> {
  get message(): string | JSX.Element[] {
    if (typeof this.props.message === 'string') {
      return this.props.message;
    }

    const now = Date.now();

    return this.props.message.map((m, index) => {
      return index === 0 ? (
        <Fragment>{m}</Fragment>
      ) : (
        <div key={`${Math.random()}-${now}`}>{m}</div>
      );
    });
  }

  render(): JSX.Element {
    return this.props.message.length > 0 ? (
      <div className={classes.Root}>{this.message}</div>
    ) : null;
  }
}
