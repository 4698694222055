import React, { Component, FormEvent, RefObject } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import classes from './App.module.scss';

import Navbar from './Navbar/Navbar';
import Splash from './Splash/Splash';
import Welcome from './Welcome/Welcome';

import { db } from './firebase';
import Reply from './Reply/Reply';
import PrivateRoute from './PrivateRoute';
import Travel from './Travel/Travel';
import Information from './Information/Information';

export interface IGuest {
  [index: string]: string | number | boolean | undefined;
  firstName?: string;
  lastName?: string;
  id?: string;
  isCeremonyGuest?: boolean;
  isGoing?: boolean;
  isPlusOne?: boolean;
  additionalComments?: string;
  dietaryRequirements?: string;
}

export interface IAppState {
  guests: IGuest[];
  submitMessage: string | string[];
}

class App extends Component<{}, IAppState> {
  root: RefObject<HTMLDivElement>;

  state: IAppState;

  constructor(props: {}) {
    super(props);
    this.root = React.createRef();
    this.state = {
      guests: [],
      submitMessage: null,
    };
  }

  handleRetrieveInvitation = (e: FormEvent, invitationId: string): void => {
    e.preventDefault();
    // db.enableNetwork();

    const guestRef = db.collection('guests');
    const invitationsRef = db.collection('invitations');

    invitationsRef
      .doc(invitationId)
      .get()
      .then(invitation => {
        if (!invitation.exists) {
          this.setState({
            submitMessage: [
              `Oh no, the code doesn't match an invitation! Please check it and try again.`,
              'Codes are cAsE-sEnSiTiVe',
            ],
          });
          return;
        }

        const guests = invitation.data()!.guestIds;

        for (let guest of guests) {
          const docRef = guestRef.doc(guest);

          docRef.get().then(doc => {
            if (doc.exists) {
              const curGuests = [...this.state.guests!];

              const guestData = { ...doc.data(), id: guest };

              curGuests.push(guestData as IGuest);
              this.setState({
                guests: curGuests,
                submitMessage: null,
              });
            }
          });
        }
      });
  };

  renderSplash = () => {
    const { guests } = this.state;

    return (
      <Splash
        isInvitationIdMatched={!!guests && guests.length > 0}
        onRetrieveInvitation={this.handleRetrieveInvitation}
        submitMessage={this.state.submitMessage}
      />
    );
  };

  isCeremonyInvitation = (): boolean => {
    return this.state.guests!.some(g => g.isCeremonyGuest!);
  };

  render() {
    return (
      <BrowserRouter>
        <Switch>
          <div ref={this.root} className={classes.Root}>
            <Navbar />

            <div className={classes.Content}>
              <Route path="/" exact render={this.renderSplash} />
              <PrivateRoute
                path="/welcome"
                exact
                renderedComponent={<Welcome guests={this.state.guests} />}
                isAuthenticated={this.state.guests!.length > 0}
              />
              <PrivateRoute
                path="/info"
                exact
                renderedComponent={<Information guests={this.state.guests} />}
                isAuthenticated={this.state.guests!.length > 0}
              />
              <PrivateRoute
                path="/rsvp"
                exact
                renderedComponent={
                  <Reply
                    guests={this.state.guests}
                    onSubmit={guests => this.setState({ guests: guests })}
                  />
                }
                isAuthenticated={this.state.guests.length > 0}
              />
              <PrivateRoute
                path="/travel"
                exact
                renderedComponent={
                  <Travel isCeremonyInvitation={this.isCeremonyInvitation()} />
                }
                isAuthenticated={this.state.guests.length > 0}
              />
            </div>
          </div>
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
